/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "./api";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const engineWrite = /* GraphQL */ `mutation EngineWrite(
  $clientRequestHandle: String
  $engId: ID!
  $itemList: [EngineWriteItem!]!
) {
  engineWrite(
    clientRequestHandle: $clientRequestHandle
    engId: $engId
    itemList: $itemList
  ) {
    clientRequestHandle
    itemList {
      name
      result
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.EngineWriteMutationVariables,
  APITypes.EngineWriteMutation
>;
export const execCommand = /* GraphQL */ `mutation ExecCommand($data: ExecCommandInput!) {
  execCommand(data: $data)
}
` as GeneratedMutation<
  APITypes.ExecCommandMutationVariables,
  APITypes.ExecCommandMutation
>;
export const importFile = /* GraphQL */ `mutation ImportFile($data: ImportFileInput!) {
  importFile(data: $data) {
    id
    url
    __typename
  }
}
` as GeneratedMutation<
  APITypes.ImportFileMutationVariables,
  APITypes.ImportFileMutation
>;
export const modifyCharger = /* GraphQL */ `mutation ModifyCharger($data: ModifyChargerInput!) {
  modifyCharger(data: $data)
}
` as GeneratedMutation<
  APITypes.ModifyChargerMutationVariables,
  APITypes.ModifyChargerMutation
>;
export const modifyChargerControl = /* GraphQL */ `mutation ModifyChargerControl(
  $chargerId: Int!
  $data: ModifyChargerControlInput!
  $transactionId: Int
) {
  modifyChargerControl(
    chargerId: $chargerId
    data: $data
    transactionId: $transactionId
  )
}
` as GeneratedMutation<
  APITypes.ModifyChargerControlMutationVariables,
  APITypes.ModifyChargerControlMutation
>;
export const modifyConnector = /* GraphQL */ `mutation ModifyConnector($data: ModifyConnectorInput!) {
  modifyConnector(data: $data)
}
` as GeneratedMutation<
  APITypes.ModifyConnectorMutationVariables,
  APITypes.ModifyConnectorMutation
>;
export const modifyRfid = /* GraphQL */ `mutation ModifyRfid($data: ModifyRfidInput!) {
  modifyRfid(data: $data)
}
` as GeneratedMutation<
  APITypes.ModifyRfidMutationVariables,
  APITypes.ModifyRfidMutation
>;
export const modifySite = /* GraphQL */ `mutation ModifySite($data: ModifySiteInput!) {
  modifySite(data: $data)
}
` as GeneratedMutation<
  APITypes.ModifySiteMutationVariables,
  APITypes.ModifySiteMutation
>;
export const modifyTariff = /* GraphQL */ `mutation ModifyTariff($data: ModifyTariffInput!) {
  modifyTariff(data: $data)
}
` as GeneratedMutation<
  APITypes.ModifyTariffMutationVariables,
  APITypes.ModifyTariffMutation
>;
export const modifyUser = /* GraphQL */ `mutation ModifyUser($data: ModifyUserInput!) {
  modifyUser(data: $data)
}
` as GeneratedMutation<
  APITypes.ModifyUserMutationVariables,
  APITypes.ModifyUserMutation
>;
export const newAlarmData = /* GraphQL */ `mutation NewAlarmData($data: NewAlarmDataInput!) {
  newAlarmData(data: $data) {
    ackSource
    ackTimestamp
    alarmTimestamp
    clearTimestamp
    description
    isActive
    siteId
    source
    value
    __typename
  }
}
` as GeneratedMutation<
  APITypes.NewAlarmDataMutationVariables,
  APITypes.NewAlarmDataMutation
>;
export const newChargerData = /* GraphQL */ `mutation NewChargerData($data: NewChargerDataInput!) {
  newChargerData(data: $data) {
    chargerCmdHasError
    chargerCmdResult
    chargerCmdSeqNr
    chargerId
    connector
    current
    energyMeter
    offeredCurrent
    offeredPower
    pingResult
    power
    sessionEnergy
    siteId
    soc
    timestamp
    voltage
    __typename
  }
}
` as GeneratedMutation<
  APITypes.NewChargerDataMutationVariables,
  APITypes.NewChargerDataMutation
>;
export const newChargerStatusNotification = /* GraphQL */ `mutation NewChargerStatusNotification(
  $data: NewChargerStatusNotificationInput!
) {
  newChargerStatusNotification(data: $data) {
    chargerId
    connector
    errorCode
    id
    info
    siteId
    source
    statusCode
    timestamp
    __typename
  }
}
` as GeneratedMutation<
  APITypes.NewChargerStatusNotificationMutationVariables,
  APITypes.NewChargerStatusNotificationMutation
>;
export const newChargingSessionData = /* GraphQL */ `mutation NewChargingSessionData($data: NewChargingSessionDataInput!) {
  newChargingSessionData(data: $data) {
    chargerId
    connector
    costs
    id
    rfid
    siteId
    startDate
    startMeter
    stopDate
    stopMeter
    stopReason
    tariffId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.NewChargingSessionDataMutationVariables,
  APITypes.NewChargingSessionDataMutation
>;
export const newReport = /* GraphQL */ `mutation NewReport($data: NewReportDataInput!) {
  newReport(data: $data) {
    customization
    error
    outputFormat
    reportId
    result
    siteId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.NewReportMutationVariables,
  APITypes.NewReportMutation
>;
export const newTrendData = /* GraphQL */ `mutation NewTrendData($data: NewTrendDataInput!) {
  newTrendData(data: $data) {
    chargerId
    costs
    energy
    energymeter
    energyoffrel
    maxdisabled
    pmax
    power
    regperiod
    siteId
    soc
    targetenergy
    tariff
    timestamp
    tl_id
    trendpmax
    __typename
  }
}
` as GeneratedMutation<
  APITypes.NewTrendDataMutationVariables,
  APITypes.NewTrendDataMutation
>;
export const registerDataListener = /* GraphQL */ `mutation RegisterDataListener($data: RegisterDataListenerInput!) {
  registerDataListener(data: $data)
}
` as GeneratedMutation<
  APITypes.RegisterDataListenerMutationVariables,
  APITypes.RegisterDataListenerMutation
>;
export const triggerChargerCommand = /* GraphQL */ `mutation TriggerChargerCommand(
  $args: String
  $chargerId: Int!
  $command: ChargerCommand!
  $seqNr: Int!
) {
  triggerChargerCommand(
    args: $args
    chargerId: $chargerId
    command: $command
    seqNr: $seqNr
  )
}
` as GeneratedMutation<
  APITypes.TriggerChargerCommandMutationVariables,
  APITypes.TriggerChargerCommandMutation
>;
export const updateLiveData = /* GraphQL */ `mutation UpdateLiveData($data: UpdateLiveDataInput!) {
  updateLiveData(data: $data) {
    chargerAuth
    chargerCurI
    chargerCurP
    chargerCurU
    chargerDevMaxI
    chargerESession
    chargerETotal
    chargerSetMaxI
    chargerState
    commandResult
    commandSeqNr
    curConditionStatus
    curEnergy
    curEnergyMeterValues
    curInputStatus
    curModuleStatus
    curOutputStatus
    curOutputStatusPhysical
    curPower
    curTimeOfPeriod
    curTrendLevel
    pingResult
    regulationPriorityStatus
    siteId
    targetValues
    timestamp
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateLiveDataMutationVariables,
  APITypes.UpdateLiveDataMutation
>;
